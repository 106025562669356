import React from "react";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import Container from "../components/chat-channel/Container";


export default function Cookies() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Conversational AI with Workflow Automation | Workativ Assistant"
        description="Workativ Assistant is the leading provider of free conversational ai and workflow automation in a single platform to automate workplace support. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Free Conversational AI with Workflow Automation | Workativ Assistant"
        ogDescription="Workativ Assistant is the leading provider of free conversational ai and workflow automation in a single platform to automate workplace support. "
      />
      <Container>
        <Layout
          logoFor="ASSISTANT"
          backgroundColor={"bg_feature _assistant_pricing"}
        >
          <CookiesContent />
        </Layout>
      </Container>
    </>
  );
}

function CookiesContent() {
  return (
    <section class="w-100 float-left mt-5">
      <div className="container">
        <h1 className="font-section-sub-header-bold">What are cookies? </h1>
        <p className="font-section-normal-text">
          A cookie is a small text file which includes a unique identifier that
          is sent by a web server to the browser on your computer, mobile phone
          or any other internet enabled device when you visit an on-line site.
          Cookies and similar technologies are widely used to make websites work
          efficiently and to collect information about your online preferences.
          For simplicity, we refer to all these technologies as "cookies".{" "}
        </p>
        <p className="font-section-normal-text">
          Some of our website pages may contain electronic images known as web
          beacons (also known as clear gifs, tags or pixels) that allow us to
          count users who have visited our pages. Web beacons collect only
          limited information, e.g. a cookie number, time and date of a page
          view, and a description of the page on which the web beacon resides.
          We may also carry web beacons placed by third party advertisers. These
          beacons do not carry any information that could directly identify you.
        </p>
        <h2 className="font-section-sub-header-bold">How do we use cookies?</h2>
        <p className="font-section-normal-text">
          We use cookies and other tracking technologies to customise content
          and advertising, provide social media features and to see how our
          visitors move through our website. We use this information to make
          decisions about ways to improve the services we offer you.
        </p>
        <p className="font-section-normal-text">
          We may engage third party tracking and advertising providers such as
          those named below to act on our behalf to track and analyse your usage
          of our website through the use of cookies. These third parties
          collect, and share with us, usage information about visits to our
          website and, sometimes by correlating this information with other
          information (e.g. your IP address), measure and research the
          effectiveness of our advertisements, track page usage, help us target
          our recommendations and advertising, and track use of our
          recommendations and advertisements.
        </p>

        <table className="cookies_new_table mb-4">
          <tr>
            <th className="font-section-normal-text-medium">Cookie Type</th>
            <th className="font-section-normal-text-medium">
              What these cookies do{" "}
            </th>
          </tr>
          <tr>
            <td className="font-section-normal-text">Essential cookies </td>
            <td className="font-section-normal-text">
              These cookies are essential for parts of our website to operate.
              They enable users to move around our website and allow us to
              recognise a user within our website so that we can provide them
              with service they asked for such as remembering the user's sign-in
              details.{" "}
            </td>
          </tr>
          <tr>
            <td className="font-section-normal-text">Targeting cookies </td>
            <td className="font-section-normal-text">
              We may use other cookies to target and re-target visitors to our
              websites with digital advertising that is most relevant to the
              user. These cookies are also used to limit the number of times you
              see an advert or particular content, as well as help measure the
              effectiveness of an advertising or marketing campaign. Using
              existing cookies from many sites already on your computer, we
              partner with 3rd party companies to deliver advertising to
              specific computers. For more information about digital advertising
              visit AdChoices at:{" "}
              <a href="https://youradchoices.com/">www.youradchoices.com.</a>
            </td>
          </tr>
          <tr>
            <td className="font-section-normal-text">Social media cookies</td>
            <td className="font-section-normal-text">
              These cookies allow users to share our websites on social media
              such as Facebook and Twitter. These cookies are not within our
              control. Please refer to the respective privacy policies of the
              social media provider for how their cookies work.
            </td>
          </tr>
          <tr>
            <td className="font-section-normal-text">Performance cookies</td>
            <td className="font-section-normal-text">
              These cookies collect information on how users use our websites,
              in order to help us improve areas such as navigation, and to help
              us fix technical issues or errors. For example, we use these
              cookies to help us understand how you arrive at our website,
              browse or use our website and highlight areas where we can improve
              our website.
            </td>
          </tr>
          <tr>
            <td className="font-section-normal-text">Functionality cookies</td>
            <td className="font-section-normal-text">
              These cookies help us customise our website content based on a
              user's preferences. They remember the user's choices, their
              language, the country pages visited and any changes the user makes
              to text size or other parts of our website pages. The information
              these cookies collect may be anonymised and they cannot track
              browsing activity on other websites.
            </td>
          </tr>
        </table>

        <p className="font-section-normal-text">
          If you have any questions regarding cookies which are deployed on our
          website, you can contact us at{" "}
          <a href="admin@workativ.com">admin@workativ.com.</a>
        </p>
        <p className="font-section-normal-text">
          You can find more information about cookies, behavioural advertising
          and online privacy at{" "}
          <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>{" "}
          or&nbsp;
          <a href="https://www.youronlinechoices.eu/">
            www.youronlinechoices.eu.{" "}
          </a>
        </p>
        <h1 className="font-section-sub-header-bold">
          How do I reject cookies?
        </h1>
        <p className="font-section-normal-text"  style={{ wordBreak:"break-all"}}>
          If you do not want to be tracked by Google Analytics cookies you can
          opt-out by installing a browser plug-in here:&nbsp;
          <a href="https://tools.google.com/dlpage/gaoptout/">
            https://tools.google.com/dlpage/gaoptout/
          </a>
        </p>
        <p className="font-section-normal-text">
          At any time, you can prevent cookies from being set on your browser.
          For instructions on how to block, delete or disable any cookies,
          please consult your browser's 'Help' or 'Support' section. Please note
          that by deleting our cookies or disabling future cookies you may not
          be able to access certain areas or features of our website.
        </p>
        <p className="font-section-normal-text">
          {" "}
          Please consult the following links for information on cookie
          management and blocking according to your browser:{" "}
        </p>
        <ul className="font-section-normal-text w-100 float-left"  style={{ wordBreak:"break-all"}}>
          <li>
            Explorer:{" "}
            <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
              http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11{" "}
            </a>
          </li>
          <li>
            Firefox:{" "}
            <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences{" "}
            </a>
          </li>
          <li>
            Chrome:
            <a href="https://support.google.com/chrome/answer/95647?hl=en">
              {" "}
            https://support.google.com/chrome/answer/95647?hl=en{" "}
            </a>
          </li>
          <li>
            Safari:
            <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac">
              {" "}
              https://support.apple.com/kb/PH19214?locale=en_GB
            </a>{" "}
          </li>
        </ul>
        <p className="font-section-normal-text">
          You can also find more information and manage cookie storage at:
          <a href="https://www.youronlinechoices.eu/">
            {" "}
            www.youronlinechoices.eu.
          </a>{" "}
        </p>
      </div>
    </section>
  );
}
